<template>
  <el-card>
    <el-row style="margin: 0 0 15px">
      <el-button type="primary" @click="add">添加产品</el-button>
    </el-row>

    <el-table :data="data" border style="width: 100%; margin:15px 0">
      <el-table-column align="center" prop="title" label="标题"></el-table-column>
      <el-table-column align="center" prop="icon" label="图标"></el-table-column>
      <el-table-column align="center" prop="list" label="内容"></el-table-column>
      <el-table-column align="center" prop="egDepict" label="英文描述"></el-table-column>
      <el-table-column align="center" prop="plan" label="展示图"></el-table-column>
      <el-table-column align="center" label="产品图片"> 
        <template v-slot="scope">
                   <el-image
                       style="width: 50px; height: 50px"
                       :src="scope.row.img"
                       :preview-src-list="[scope.row.img]">
                  </el-image>
        </template>
      </el-table-column>

      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button @click="edit(scope.row)" size="small" type="text">编辑</el-button>
          <el-button :disabled="!(scope.row.title==123)" @click="del(scope.row.id)" size="small" type="text">删除</el-button>
        </template>
      </el-table-column>

    </el-table>

       <el-pagination
           style="text-align:right;"
           @size-change="handleSizeChange"
           @current-change="handleCurrentChange"
           :current-page="queryInfo.pagenum"
           :page-sizes="[2,4,6,8,10]"
           :page-size="queryInfo.pagesize"
           layout="total, sizes, prev, pager, next, jumper"
           :total="total">
    </el-pagination>

     <!--    弹窗-->
    <el-dialog title="添加产品" :visible.sync="dialogFormVisible" :before-close="dialogClose" >
      <el-form :model="form" ref="form" :rules="rules">

        <el-form-item label="标题"  prop="title" :label-width="formLabelWidth">
          <el-input v-model="form.title"/>
        </el-form-item>

        <el-form-item label="产品图片" prop="img" :label-width="formLabelWidth">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="imgSuccess">
            <img v-if="form.img" :src="form.img" class="img" alt="">
            <i v-else class="el-icon-plus img-uploader-icon"></i>
          </el-upload>

        </el-form-item>

        <el-form-item label="展示图" prop="plan" :label-width="formLabelWidth">
          <el-input v-model="form.plan"/>
        </el-form-item>

        <el-form-item label="图标" prop="icon" :label-width="formLabelWidth">
          <el-input v-model="form.icon"/>
        </el-form-item>

        <el-form-item label="英文描述" prop="egDepict" :label-width="formLabelWidth">
          <el-input v-model="form.egDepict"/>
        </el-form-item>


        <el-form-item label="内容" prop="list" :label-width="formLabelWidth">
          <div class="programList">
            <!-- tag标签 -->
            <el-tag
              v-model="form.list"
              :key="tag"
              v-for="tag in form.list"
              closable
              :disable-transitions="false"
              @close="handleClose(tag)">
              {{tag}}
            </el-tag>
            <el-input
              class="input-new-tag"
              v-if="inputVisible"
              v-model="inputValue"
              ref="saveTagInput"
              size="small"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
            >
            </el-input>
            <el-button v-else class="button-new-tag" size="small" @click="showInput">新增</el-button>

          </div>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible=false" >取 消</el-button>
        <el-button type="primary" @click="addModifyProduct">确 定</el-button>
      </div>
    </el-dialog>
  </el-card>

</template>

<script>

import {addModifyProduct,pageProduct,delProduct} from '@/api/sys.js'

export default {
  name: "product",
  data() {
    return {
      dialogFormVisible:false,
      formLabelWidth: '100px',
      uploadUrl: process.env.VUE_APP_BASE_URL + '/file/oss',
      imageUrl:'',
      
      total:0,
      queryInfo: {
        // query: "",
        pageNum: 1,
        pageSize: 10,
      },


      // tag标签
      inputVisible: false,
      inputValue: '',

      data:[],
      form: {
        title: '',
        img: '',
        plan: '',
        egDepict: '',
        icon: '',
        list:[], 
      },
      rules:{
        title:[{ required: true, message: '请输入标题', trigger: 'blur' }],
        img:[{ required: true, message: '请上传图片', trigger: 'blur' }],
        plan:[{ required: true, message: '请输入展示图', trigger: 'blur' }],
        egDepict:[{ required: true, message: '请输入英文描述', trigger: 'blur' }],
        icon:[{ required: true, message: '请输入图标', trigger: 'blur' }],
        list:[{required:true,message:'请输入内容', trigger:'blur'}]
      }
    }
  },

  created(){
    this.pageProduct()
  },

  methods: {
    add(){
      this.resetForm()
      this.form={
        title: '',
        img: '',
        plan: '',
        egDepict: '',
        icon: '',
        list:[], 
      }
      this.dialogFormVisible=true
    },

    edit(r) {
      let row={...r}
      this.form=row
      this.dialogFormVisible=true
    },

    //表单重置
    resetForm() {
      this.$nextTick(()=>{
        if(this.$refs['form']!==undefined)
          this.$refs['form'].resetFields();
        })
    },

    dialogClose(done){
      this.resetForm()
      done()
    },


    async addModifyProduct(){
      const validate= await this.$refs['form'].validate()
      if(!validate) return
      const {data,code,msg}= await addModifyProduct(this.form)

      if(code!=200) return this.$message.error(msg)
      this.$message.success(msg)
      this.dialogFormVisible=false

      this.pageProduct()
    },

    async pageProduct(){
      const {data,code,msg,total}=await pageProduct(this.queryInfo)

      this.data=data
      this.total=total
    },

    async del(par){
      par=par.split()
      const {data,code,msg}=await delProduct(par)
      if(code!=200) return this.$message.error(msg)
      this.$message.success(msg)
      this.pageProduct()
    },


    // async getData() {
    //   const {data, code, msg} = await getArticleList(this.queryInfo);
    //   if (code !== 200) {
    //     this.$message.error(msg);
    //     return;
    //   }
    //   this.data = data;
    // },

    // 图片上传成功
    imgSuccess(file){
      const {data}=file
      this.form.img=data.url
      console.log(this.form.img);
    },


    // 标签处理
    handleClose(tag) {
        this.form.list.splice(this.form.list.indexOf(tag), 1);
      },

      showInput() {
        this.inputVisible = true;
        this.$nextTick(_ => {
          this.$refs.saveTagInput.$refs.input.focus();
        });
      },

      handleInputConfirm() {
        let inputValue = this.inputValue;
        if (inputValue) {
          this.form.list.push(inputValue);
        }
        this.inputVisible = false;
        this.inputValue = '';
      },


    // ****************************************************************************************
    // async del(id) {
    //   const {data, code, msg} = await delArticle(id);
    //   if (code !== 200) {
    //     this.$message.error(msg);
    //     return;
    //   }
    //   this.$message.success(msg);
    //   this.getData()
    // },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      console.log(this.queryInfo);
      this.pageProduct()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.pageProduct()

    },

  },

}
</script>

<style lang="less" scoped>

/deep/ .el-dialog__body {
  padding: 10px 50px 0 0;
}

// 图片上传样式
/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
/deep/ .img-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 775px;
  height: 350px;
  line-height: 350px;
  text-align: center;
}

/deep/ .avatar {
  width: 178px;
  height: 178px;
  display: block;
}

/deep/ .img {
  width: 775px;
  height: 350px;
  display: block;
}

.programList {
  border: 1px solid #d7d5d5;
  padding: 10px;

  > div {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
    align-content: center;

    > div {
      margin: 0 15px;
      display: flex;
      align-items: center;
    }
  }

  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }

}
</style>
